import '../scss/qhomewidget.scss';
"use-strict"
let jQuery = require('jquery');

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

window.J = jQuery;
window.Jd = J(document);

J(function(){

    actionsScroll();
    J(window).on('scroll', function(){    
        actionsScroll();
    });

    imgBack();
    animationScroll();

    J(".display_nav .footer_block ul li a").append('<i class="fa-solid fa-messages"></i>');

    if( J(window).innerWidth() < 1000  ){
        J(".products.owl-carousel").owlCarousel({
             //Basic Speeds
            pagination: false,

            //Autoplay
            autoPlay : true,

            // Responsive
            responsive:{
                0:{
                    items:2
                },
                900:{
                    items:3
                }
            }
        });
    }

    J(".flex--lookbook .owl-carousel").owlCarousel({
        //Basic Speeds
        pagination: false,
        margin:9,
        loop: true,

        //Autoplay
        autoPlay : true,
        autoplayTimeout: 0,
        autoplaySpeed: 5000,

        // Responsive
        items : 3,
        responsive:{
            0:{
                items:2,
            },
            600:{
                items:2,
            }
        }
   });
});

function imgBack(){
    J('.backImg').each(function(){
        var urlImgCurrent = J(this).children('img').attr('src');
        J(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
        J(this).find('img').remove();			
    });
}

function actionsScroll(){
    J('.site-search').removeClass('active');

    var scrolled = J(window).scrollTop();

    if(scrolled > 0 ){
        J('body').addClass('scrolling');
    } else {
        J('body').removeClass('scrolling');
    }

    if(scrolled > J(window).outerHeight() - J('#iqitmegamenu-horizontal').innerHeight() - 36){
        J('.header-content').addClass('scroll-menu');
    } else {
        J('.header-content').removeClass('scroll-menu');
    }

    if(scrolled > J('.header-container').innerHeight() * 0.5 ){
        J('.header-content').addClass('scroll-menu--under');
    } else {
        J('.header-content').removeClass('scroll-menu--under');
    }
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });
    
    // NEWS
    if( $(window).innerWidth() > 1000  ){
        var heightScroll = -$(".section--news .products--col-1").innerHeight() + ($(window).innerHeight()*0.75);
        var heightScroll2 = $(".section--news .products--col-2").innerHeight() - ($(window).innerHeight()*0.75);
        var scrollNumber = $(".section--news .products--col-1 .product").length;
        var scrollLevel = ($(".section--news .products--scrollbar .scroll-timeline").innerHeight() - $(".section--news .products--scrollbar .scroll-bar").innerHeight()) / scrollNumber;

        gsap.to(".section--news", {
            scrollTrigger:{
                trigger: ".section--news",
                start: "top top",
                scrub: true,
                pin: true
            }
        });

        gsap.to(".section--news .products--col-1", {
            scrollTrigger:{
                trigger: ".section--news",
                start: "top top",
                scrub: true
            },
            y: heightScroll
        });

        gsap.to(".section--news .products--col-2", {
            scrollTrigger:{
                trigger: ".section--news",
                start: "top top",
                scrub: true
            },
            y: heightScroll2
        });

        gsap.to(".section--news .products--scrollbar .scroll-bar", {
            scrollTrigger:{
                trigger: ".section--news",
                start: "top top",
                scrub: true
            },
            y: scrollLevel * scrollNumber
        });

        var num = {var:1};
        gsap.timeline({
            scrollTrigger:{
                trigger: ".section--news",
                start: "top top",
                scrub: true
            },
        })
        .to(num, {var:scrollNumber, onUpdate:changeNumber});

        function changeNumber() {
            qnews_scroll_min.innerHTML = "0"+Math.trunc(num.var);
        }
    }

    

    // POPULAIRES
    if( $(window).innerWidth() > 1000  ){
        gsap.to(".section--populaires", {
            scrollTrigger:{
            trigger: ".section--populaires",
            start: "top 70px",
            scrub: true,
            pin: true
            }
        });

        gsap.to(".section--populaires .products", {
            scrollTrigger:{
                trigger: ".section--populaires",
                start: "top 70px",
                scrub: true
            },
            y: "-30vh"
        });

        gsap.to(".section--populaires .products--scrollbar .scroll-bar", {
            scrollTrigger:{
                trigger: ".section--populaires",
                start: "top 70px",
                scrub: true
            },
            y: "205%"
        });
    }
}